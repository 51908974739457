exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-email-bestaetigung-js": () => import("./../../../src/pages/email-bestaetigung.js" /* webpackChunkName: "component---src-pages-email-bestaetigung-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-neues-projekt-js": () => import("./../../../src/pages/neues-projekt.js" /* webpackChunkName: "component---src-pages-neues-projekt-js" */),
  "component---src-pages-projekt-js": () => import("./../../../src/pages/projekt.js" /* webpackChunkName: "component---src-pages-projekt-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-registrieren-js": () => import("./../../../src/pages/registrieren.js" /* webpackChunkName: "component---src-pages-registrieren-js" */)
}

